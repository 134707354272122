<template>
  <RouterView :lang="lang" @switch-lang="switchLang"/>
</template>

<script>
import { RouterView } from 'vue-router'
import en from '@/assets/languages/en.json'
import nl from '@/assets/languages/nl.json'
import de from '@/assets/languages/de.json'
import fr from '@/assets/languages/fr.json'

export default {
  components: {  },
  data() {
    return {
      lang: null,
    }
  },
  beforeMount() {
    if (window.location.hostname === 'thejockeyandhorsecompany.nl') {
      document.documentElement.lang = 'nl'
      this.lang = nl
    } else {
      if (window.navigator.language.includes('nl')) {
        document.documentElement.lang = window.navigator.language
        this.lang = nl
      } else if (window.navigator.language.includes('de')) {
        document.documentElement.lang = window.navigator.language
        this.lang = de
      } else if (window.navigator.language.includes('fr')) {
        document.documentElement.lang = window.navigator.language
        this.lang = fr
      } else {
        document.documentElement.lang = 'en'
        this.lang = en
      }
    }
  },
  methods: {
    switchLang(id) {
      document.documentElement.lang = id
      if (id === 'nl') {
        this.lang = nl
      } else if (id === 'de') {
        this.lang = de
      } else if (id === 'fr') {
        this.lang = fr
      } else {
        this.lang = en
      }
    }
  }
}
</script>